<template>
  <div>
    <OnboardingHeader/>
    <div class="tw-flex feedback tw-p-4  tw-justify-center tw-pt-28" style="margin: 0 auto">
      <span class="tw-mb-16">How was your assessment?</span>
    </div>
    <div class="tw-flex feedback tw-p-4  tw-justify-center" >

      <div class="tw-flex tw-justify-center tw-flex-wrap md:tw-justify-between">
        <div class="md:tw-mr-8 tw-mr-3	">
          <TerribleFeedback :selected="selected" @click="selected='terrible'"/>
            <h2 :style="[selected==='terrible'?{color:'#008EEF'}:{color:'#9CABB5'}]">Terrible</h2>
        </div>
        <div  class="md:tw-mr-8 tw-mr-3">
          <NotGoodFeedback :selected="selected" @click="selected= 'not_good'"/>
          <h2 :style="[selected==='not_good'?{color:'#008EEF'}:{color:'#9CABB5'}]">Not Good</h2>
        </div>
        <div  class="md:tw-mr-8 tw-mr-3">
          <AverageFeedback :selected="selected" @click="selected='average'"/>
          <h2 :style="[selected==='average'?{color:'#008EEF'}:{color:'#9CABB5'}]">Average</h2>
        </div>
        <div  class="md:tw-mr-8 tw-mr-3">
          <GoodFeedback :selected="selected" @click="selected= 'good'"/>
          <h2 :style="[selected==='good'?{color:'#008EEF'}:{color:'#9CABB5'}]">Good</h2>
        </div>
        <div  class="tw-mr-">
          <ExcellentFeedback :selected="selected" @click="selected='excellent'"/>
          <h2 :style="[selected==='excellent'?{color:'#008EEF'}:{color:'#9CABB5'}]">Excellent</h2>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-px-4  tw-py-10 md:tw-w-450" style="margin:0 auto;">
      <v-textarea v-model="summary" class="tw-flex tw-justify-center" solo></v-textarea>
      <div class="tw-flex ">
        <v-spacer :style="[$vuetify.breakpoint.mobile?{display:'none'}:{display: 'block'}]"/>
        <BaseButton width="135px"  :loading="loading"  @click="submitFeedback" :block="$vuetify.breakpoint.mobile" class="tw-mt-10" button-text="Give Feedback"  ></BaseButton>

      </div>

    </div>

  </div>

</template>

<script>
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
import TerribleFeedback from "@/components/reusables/TerribleFeedback";
import NotGoodFeedback from "@/components/reusables/NotGoodFeedback";
import GoodFeedback from "@/components/reusables/GoodFeedback";
import ExcellentFeedback from "@/components/reusables/ExcellentFeedback";
import AverageFeedback from "@/components/reusables/AverageFeedback";
import BaseButton from "@/components/reusables/BaseButton";
import {giveAssessmentFeedback} from "@/services/api/APIService";
export default {
name: "AssessmentFeedback",
  components: {
    BaseButton,
    AverageFeedback, ExcellentFeedback, GoodFeedback, NotGoodFeedback, TerribleFeedback, OnboardingHeader},
  data() {
    return{
      summary:'',
      selected:'',
      loading:false
    }
  },
  computed:{
    getLoginUser() {
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    }
  },
  methods: {
    submitFeedback(){
      this.loading=true
      let id
      if(this.$route.query.from){
        id = 'program_id'
      }
      else {
        id='job_id'
      }
      giveAssessmentFeedback({
        [id]: this.$route.query.jobId || this.$route.query.id,
        email: this.getLoginUser.email,
        reaction: this.selected,
        summary: this.summary,
        test_type:this.$route.query.category.split(' ')[0].toLowerCase()
      })
      .then(res=>{
        console.log(res.data)
        this.$router.push({name:'AssessmentTypeView',query:this.$route.query})
      })
      .catch(err=>{
        console.error(err.response)
        if(err.response.data.detail==='You have submitted a feedback already'){
          this.$router.push({name:'AssessmentTypeView',query:this.$route.query})

        }
      })
      .finally(()=>{this.loading= false})

    }
  }
}
</script>

<style scoped>
.feedback h2{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  /*color: #9CABB5;*/
}
.feedback span {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
  text-align: center;
}

</style>
