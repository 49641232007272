<template>
  <svg @click="$emit('click')"  :class="selected==='terrible'?'highlight':''" class="tw-flex md:tw-w-20 tw-w-12 tw-justify-center"  height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="circle" cx="36.2969" cy="36" r="35" fill="white" stroke="#D0DCE4" stroke-width="2"/>
    <path id="path1" d="M20.9162 22.2148L26.3146 26.6133H19.1172" stroke="#D0DCE4" stroke-width="2"/>
    <path id="path2" d="M52.5174 22.2148L47.119 26.6133H54.3164" stroke="#D0DCE4" stroke-width="2"/>
    <path id="path3" d="M20.2154 50.3398C21.0902 48.228 22.3723 46.309 23.9887 44.6927C25.6051 43.0763 27.524 41.7941 29.6359 40.9194C31.7478 40.0446 34.0113 39.5944 36.2971 39.5944C38.583 39.5944 40.8465 40.0446 42.9584 40.9194C45.0703 41.7941 46.9892 43.0763 48.6056 44.6927C50.222 46.309 51.5041 48.228 52.3789 50.3398" stroke="#D0DCE4" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
name: "TerribleFeedback",
  props: {
    selected:[String]
  }
}
</script>

<style scoped lang="scss">
.highlight{
  #circle{
    stroke: #008EEF;
  }
  #path1 {
    stroke: #008EEF;
  }
  #path2 {
    stroke: #008EEF;
  }
  #path3 {
    stroke: #008EEF;
  }
}
.highlight {
  stroke: #008EEF !important;
}
</style>
