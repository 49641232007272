<template>
  <svg @click="$emit('click')" :class="selected==='excellent'?'svgClick':''" class="md:tw-w-20 tw-w-12" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="circle" cx="36.2969" cy="36" r="35" fill="white" stroke="#D0DCE4" stroke-width="2"/>
    <path id="path1" d="M20.713 22.2148L26.1115 26.6133H18.9141" stroke="#D0DCE4" stroke-width="2"/>
    <path id="path2" d="M52.3143 22.2148L46.9159 26.6133H54.1133" stroke="#D0DCE4" stroke-width="2"/>
    <mask id="path-4-inside-1_34342:164892" fill="white">
      <path id="path3" d="M53.7042 42.0005C53.7042 44.2864 53.2539 46.5499 52.3792 48.6618C51.5044 50.7737 50.2222 52.6926 48.6058 54.309C46.9895 55.9253 45.0706 57.2075 42.9587 58.0823C40.8468 58.9571 38.5833 59.4073 36.2974 59.4073C34.0115 59.4073 31.748 58.9571 29.6361 58.0823C27.5242 57.2075 25.6053 55.9253 23.9889 54.309C22.3726 52.6926 21.0904 50.7737 20.2156 48.6618C19.3409 46.5499 18.8906 44.2864 18.8906 42.0005L36.2974 42.0005H53.7042Z"/>
    </mask>
    <path id="path4" d="M53.7042 42.0005C53.7042 44.2864 53.2539 46.5499 52.3792 48.6618C51.5044 50.7737 50.2222 52.6926 48.6058 54.309C46.9895 55.9253 45.0706 57.2075 42.9587 58.0823C40.8468 58.9571 38.5833 59.4073 36.2974 59.4073C34.0115 59.4073 31.748 58.9571 29.6361 58.0823C27.5242 57.2075 25.6053 55.9253 23.9889 54.309C22.3726 52.6926 21.0904 50.7737 20.2156 48.6618C19.3409 46.5499 18.8906 44.2864 18.8906 42.0005L36.2974 42.0005H53.7042Z" fill="white" stroke="#D0DCE4" stroke-width="4" mask="url(#path-4-inside-1_34342:164892)"/>
  </svg>

</template>

<script>
export default {
name: "ExcellentFeedback",
  props: {
  selected:[String]
  }
}
</script>

<style scoped lang="scss">
.svgClick{
  #circle{
    stroke: #008EEF;
  }
  #path1 {
    stroke: #008EEF;
  }
  #path2 {
    stroke: #008EEF;
  }
  #path3 {
    stroke: #008EEF;
  }
  #path4 {
    stroke: #008EEF;
  }
}

</style>
