<template>
  <svg @click="$emit('click')" :class="selected==='good'?'svgClick':''" class="md:tw-w-20 tw-w-12" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="circle" cx="36.2969" cy="36" r="35" fill="white" stroke="#D0DCE4" stroke-width="2"/>
    <circle id="path1" cx="26.0859" cy="24.2363" r="4" fill="#D0DCE4"/>
    <circle id="path2" cx="47.2891" cy="24.2363" r="4" fill="#D0DCE4"/>
    <path id="path3" d="M53.7042 39C53.7042 41.2859 53.2539 43.5494 52.3792 45.6613C51.5044 47.7732 50.2222 49.6921 48.6058 51.3084C46.9895 52.9248 45.0706 54.207 42.9587 55.0818C40.8468 55.9565 38.5833 56.4068 36.2974 56.4068C34.0115 56.4068 31.748 55.9565 29.6361 55.0818C27.5242 54.207 25.6053 52.9248 23.9889 51.3084C22.3726 49.6921 21.0904 47.7732 20.2156 45.6613C19.3409 43.5494 18.8906 41.2859 18.8906 39" stroke="#D0DCE4" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
name: "GoodFeedback",
  props: {
  selected:[String]
  }
}
</script>

<style scoped lang="scss">
.svgClick{
#circle{
  stroke: #008EEF;
}
#path1 {
  stroke: #008EEF;
  fill:#008EEF !important;

}
#path2 {
  stroke: #008EEF;
  fill:#008EEF !important;

}
#path3 {
  stroke: #008EEF;
}
}
</style>
