<template>
  <svg @click="$emit('click')" :class="selected==='average'?'svgClick':''" class="md:tw-w-20 tw-w-12" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="path1" cx="36.2969" cy="36" r="35" fill="white" stroke="#D0DCE4" stroke-width="2"/>
    <circle id="path2" cx="26.2891" cy="24.2363" r="4" fill="#D0DCE4"/>
    <circle id="path3" cx="47.4922" cy="24.2363" r="4" fill="#D0DCE4"/>
  </svg>

</template>

<script>
export default {
name: "AverageFeedback",
  props: {
    selected:[String]
  }
}
</script>


<style scoped lang="scss">
.svgClick{
  #circle{
    stroke: #008EEF;
  }
  #path1 {
    stroke: #008EEF;

  }
  #path2 {
    stroke: #008EEF;
    fill:#008EEF !important;

  }
  #path3 {
    stroke: #008EEF;
    fill:#008EEF !important;

  }
}
</style>
